<template>
  <div class="baskyt-progress slide-pop-down-enter-active">
    <div class="baskyt-progress__loader">
      <div class="baskyt-progress__stage" :class="aq"></div>
      <div class="baskyt-progress__stage" :class="ah"></div>
      <div class="baskyt-progress__stage" :class="atq"></div>
      <div class="baskyt-progress__stage" :class="af"></div>
      <img
        src="@/assets/static-assets/app-icons/white-baskyt-red.png"
        alt="Place Order Baskyt Image"
      />
    </div>
    <div class="baskyt-progress__text" v-show="$slots.text">
      <slot name="text"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}baskyt-progess`,
  data() {
    return {
      aq: "",
      ah: "",
      atq: "",
      af: ""
    };
  },
  methods: {
    animate(index, reverse) {
      if (index === 1) {
        if (!reverse) {
          this.af = this.af.replace(" baskyt-progress__stage--4", "");
          this.aq = "baskyt-progress__stage--1";
          this.ah = "baskyt-progress__stage--1";
          this.atq = "baskyt-progress__stage--1";
          this.af = "baskyt-progress__stage--1";
        } else {
          this.aq = this.aq.replace("baskyt-progress__stage--1", "");
          this.ah = this.ah.replace("baskyt-progress__stage--1", "");
          this.atq = this.atq.replace("baskyt-progress__stage--1", "");
          this.af = this.af.replace("baskyt-progress__stage--1", "");
        }
        setTimeout(() => {
          this.animate(2, reverse);
        }, 500);
      } else if (index === 2) {
        if (!reverse) {
          const x = `${this.aq} baskyt-progress__stage--2`;
          this.ah = x;
          this.atq = x;
          this.af = x;
        } else {
          this.ah = this.ah.replace(" baskyt-progress__stage--2", "");
          this.atq = this.atq.replace(" baskyt-progress__stage--2", "");
          this.af = this.af.replace(" baskyt-progress__stage--2", "");
        }
        setTimeout(() => {
          this.animate(3, reverse);
        }, 500);
      } else if (index === 3) {
        if (!reverse) {
          const x = `${this.ah} baskyt-progress__stage--3`;
          this.atq = x;
          this.af = x;
        } else {
          this.atq = this.atq.replace(" baskyt-progress__stage--3", "");
          this.af = this.af.replace(" baskyt-progress__stage--3", "");
        }
        setTimeout(() => {
          this.animate(4, reverse);
        }, 500);
      } else if (index === 4) {
        if (!reverse) {
          const x = `${this.atq} baskyt-progress__stage--4`;
          this.af = x;
        }

        setTimeout(() => {
          this.animate(1, !reverse);
        }, 500);
      }
    }
  },
  created() {
    this.animate(1, false);
  }
};
</script>

<style lang="scss" scoped></style>
