var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"main"},[(!_vm.ready)?_c('div',{staticClass:"chats"},[_c('loader'),_c('h3',[_vm._v(" It seems that connection to chat engine is compromised. Please refresh. ")])],1):(_vm.ready)?_c('div',{staticClass:"chats"},[(_vm.fetchingData)?[_c('loader')]:_vm._e(),(!_vm.fetchingData)?_c('div',[_c('div',{staticClass:"chat__message",attrs:{"id":"chat-message"}},[(_vm.messages === '')?_c('div',{staticClass:"chat__messageWrapper"},[_vm._m(0)]):_vm._e(),_vm._l((_vm.messages),function(message){return _c('div',{key:message.key},[_c('div',{staticClass:"chat__messageWrapper slide-pop-up-enter-active"},[_c('div',{class:` ${
                    message.sender === _vm.userID
                      ? 'chat__message--sent'
                      : 'chat__message--recieved'
                  }`},[_c('img',{staticClass:"chat__message--img",attrs:{"src":` ${
                      message.sender === _vm.userID
                        ? `${_vm.chats[0].imageURL}`
                        : _vm.getImgUrl()
                    }`,"alt":"user-img"}}),_c('div',{staticClass:"chat__message--wrapper"},[_c('span',{staticClass:"chat__message__text"},[_vm._v(_vm._s(message.text)+" ")]),_c('div',{staticClass:"chat__message--time"},[_vm._v(" "+_vm._s(_vm.humanize(message.sentAt))+" ")])])])]),(message.resolved)?_c('div',{key:message.key,staticClass:"chat__messageWrapper--resolved slide-pop-up-enter-active"},[_vm._v(" Resolved ")]):_vm._e(),_c('br')])})],2),_c('div',{staticClass:"sendMessage"},[_c('form',{staticClass:"sendMessage__wrapper",on:{"submit":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMessage),expression:"newMessage"}],staticClass:"sendMessage__wrapper--input",attrs:{"type":"text","placeholder":"Message"},domProps:{"value":(_vm.newMessage)},on:{"input":function($event){if($event.target.composing)return;_vm.newMessage=$event.target.value}}}),_c('img',{staticClass:"sendMessage__wrapper--button",attrs:{"src":require("../../../images-assets/send.png"),"alt":"send-button"},on:{"click":_vm.sendMessage}})])])]):_vm._e()],2):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat__message--recieved"},[_c('img',{staticClass:"chat__message--img",attrs:{"src":require("../../assets/static-assets/profile/user.jpg"),"alt":"user-img"}}),_c('div',{staticClass:"chat__message--wrapper"},[_c('span',{staticClass:"chat__message--text"},[_vm._v("Hey, Welcome do you need any Help.")])])])
}]

export { render, staticRenderFns }