<template>
  <div class="body">
    <div class="main">
      <div class="chats" v-if="!ready">
        <loader />
        <h3>
          It seems that connection to chat engine is compromised. Please
          refresh.
        </h3>
      </div>
      <div class="chats" v-else-if="ready">
        <template v-if="fetchingData">
          <loader />
        </template>
        <div v-if="!fetchingData">
          <div id="chat-message" class="chat__message">
            <div class="chat__messageWrapper" v-if="messages === ''">
              <div class="chat__message--recieved">
                <img
                  class="chat__message--img"
                  src="../../assets/static-assets/profile/user.jpg"
                  alt="user-img"
                />
                <div class="chat__message--wrapper">
                  <span class="chat__message--text"
                    >Hey, Welcome do you need any Help.</span
                  >
                </div>
              </div>
            </div>
            <div v-for="message in messages" :key="message.key">
              <div class="chat__messageWrapper slide-pop-up-enter-active">
                <div
                  :class="
                    ` ${
                      message.sender === userID
                        ? 'chat__message--sent'
                        : 'chat__message--recieved'
                    }`
                  "
                >
                  <img
                    class="chat__message--img"
                    :src="
                      ` ${
                        message.sender === userID
                          ? `${chats[0].imageURL}`
                          : getImgUrl()
                      }`
                    "
                    alt="user-img"
                  />
                  <div class="chat__message--wrapper">
                    <span class="chat__message__text">{{ message.text }} </span>
                    <div class="chat__message--time">
                      {{ humanize(message.sentAt) }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                :key="message.key"
                v-if="message.resolved"
                class="chat__messageWrapper--resolved  slide-pop-up-enter-active"
              >
                Resolved
              </div>
              <br />
            </div>
          </div>
          <div class="sendMessage">
            <form @submit.prevent="sendMessage" class="sendMessage__wrapper">
              <input
                type="text"
                v-model="newMessage"
                placeholder="Message"
                class="sendMessage__wrapper--input"
              />
              <img
                src="../../../images-assets/send.png"
                class="sendMessage__wrapper--button"
                @click="sendMessage"
                alt="send-button"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import {
  getStoreId,
  getUserName,
  getUserAvatar,
  getToken
} from "@/core/services/jwt.service";
import BaskytProgress from "@/view/components/common/BaskytProgress";
import ApiService from "@/core/services/api.service";

export default {
  name: "Chat",
  components: {
    loader: BaskytProgress
  },
  computed: {
    ...mapState({
      region: state => {
        if (state.regions.region === "") {
          return "";
        }
        return state.regions.region ? "US" : "PK";
      },
      stage: state => state.regions.stage
    })
  },
  data() {
    return {
      ready: false,
      chats: [],
      activeRoom: {},
      userID: "",
      fetchingData: false,
      messages: "",
      newMessage: "",
      roomKey: "",
      messageKey: "",
      users: [],
      roomExists: false,
      messageCollection: "",
      roomCollection: "",
      userCollection: ""
    };
  },
  updated() {
    this.scrollToEnd();
  },
  methods: {
    scrollToEnd() {
      const container = document.querySelector("#chat-message");
      if (container) {
        container.scrollTop = container.scrollHeight;
        container.scrollIntoView({ behavior: "smooth" });
      }
    },
    async userAuth() {
      this.fetchingData = true;
      this.userID = getStoreId();
      this.userName = getUserName();
      this.userAvatar = getUserAvatar();
      const userDetails = {
        email: `${this.userName}  @gmail.com `,
        id: this.userID,
        imageURL: this.userAvatar,
        onlineTIme: Date.now(),
        phone: "+923067761723",
        search: this.userName,
        username: this.userName
      };
      const email = "baskytrider440@gmail.com";
      const password = "baskyt112233";
      const { fire } = this.$firebase;
      await fire.auth().signInWithEmailAndPassword(email, password);
      await fire
        .database()
        .ref(`${this.userCollection}`)
        .orderByChild("id")
        .equalTo(this.userID)
        .once("value", async () => {
          await fire
            .database()
            .ref(`${this.userCollection}/${this.userID}`)
            .set(userDetails);
        });
      setTimeout(() => {
        this.fetchingData = false;
      }, 100);
    },
    async createRoom() {
      const { fire } = this.$firebase;
      this.fetchingData = true;
      await fire
        .database()
        .ref(`${this.roomCollection}`)
        .once("value", async snapshot => {
          if (snapshot.exists()) {
            const result = snapshot.val();
            Object.values(result).forEach(key => {
              if (key.members[0] === this.userID) {
                this.activeRoom = key;
                this.userDetails().then(this.findMessages());
                this.roomExists = true;
              }
            });
          }
          if (this.roomExists) return true;
          const Data = {
            archived: false,
            lastUpdated: Date.now(),
            members: [this.userID],
            roomType: "vendor-support",
            resolved: false
          };
          this.activeRoom = {
            ...Data
          };
          let keyRoom = "";
          let keyMessage = "";
          await fire
            .database()
            .ref(`${this.roomCollection}`)
            .push(Data)
            .once("value", snap => {
              keyRoom = snap.key;
            });
          await fire
            .database()
            .ref(`${this.messageCollection}`)
            .push({ roomId: keyRoom })
            .once("value", snap => {
              keyMessage = snap.key;
            });
          this.activeRoom.id = keyRoom;
          this.activeRoom.messages = keyMessage;
          await fire
            .database()
            .ref(`${this.roomCollection}/${keyRoom}`)
            .set(this.activeRoom);
          this.roomExists = true;
          return true;
        });
      this.users = [this.userID, "60a2277d6e161b718689370a"];
      this.userDetails();

      return false;
    },
    humanize(date) {
      const humanDate = moment(date).format("MMMM Do YYYY, h:mm a");
      return humanDate;
    },
    getImgUrl() {
      const images = require.context(
        "@/assets/static-assets/profile/",
        false,
        /\.jpg$/
      );
      return images(`./user.jpg`);
    },
    async userDetails() {
      const { fire } = this.$firebase;
      this.fetchingData = true;
      const email = "baskytrider440@gmail.com";
      const password = "baskyt112233";
      await fire.auth().signInWithEmailAndPassword(email, password);

      let userData = [];
      // const resultData = [];
      this.users.forEach(async user => {
        await fire
          .database()
          .ref(`${this.userCollection}/${user}`)
          .once("value", snapshot => {
            if (snapshot.exists()) {
              userData = snapshot.val();

              this.chats.push(userData);
            }
          });
      });
    },
    async sendMessage(e) {
      e.preventDefault();
      const { fire } = this.$firebase;

      // this.fetchingData = true;
      if (this.newMessage === "") return;
      // this.sendingMessage = true;
      if (this.roomExists) {
        const time = Date.now();
        // Get the current room
        // Create the message object
        const message = {
          attachments: "",
          text: this.newMessage,
          sender: this.userID,
          sentAt: time
        };
        this.newMessage = "";
        // Push the message
        await fire
          .database()
          .ref(`${this.messageCollection}/${this.activeRoom.messages}/messages`)
          .push(message)
          .then(async () => {
            this.findMessages();
            const roomData = {
              ...this.activeRoom
            };
            roomData.lastMessage = message.text;
            roomData.lastSender = message.sender;
            roomData.lastUpdated = message.sentAt;
            roomData.resolved = false;
            roomData.is_seen = false;
            await fire
              .database()
              .ref(`${this.roomCollection}/${roomData.id}`)
              .set(roomData);
          });
        await ApiService.post("/slack/message", {
          messageType: "text",
          sender: message.sender,
          message: message.text,
          token: getToken
        });
        // this.sendingMessage = false;
      } else {
        this.createRoom();
      }
      // this.fetchingData = false;
    },
    async findMessages() {
      const { fire } = this.$firebase;
      return fire
        .database()
        .ref(`${this.messageCollection}/${this.activeRoom.messages}/messages`)
        .on("value", async snapshot => {
          if (snapshot.exists()) {
            const result = snapshot.val();
            this.messages = "";
            const temp = [];
            Object.keys(result).forEach(key => {
              temp.push(result[key]);
            });
            this.messages = temp;
            if (this.activeRoom.lastSender !== this.userID) {
              const roomData = {
                ...this.activeRoom
              };
              roomData.is_seen = false;
              await fire
                .database()
                .ref(`${this.roomCollection}/${roomData.id}`)
                .set(roomData);
            }
            // });
          }
        });
      // this.fetchingData = false;
    }
  },
  mounted() {
    const { fire } = this.$firebase;
    if (!fire) {
      this.ready = false;
    } else {
      this.ready = true;
    }
    if (this.region === "US") {
      if (this.stage === "PROD") {
        this.messageCollection = "messages_us_prod";
        this.roomCollection = "rooms_us_prod";
        this.userCollection = "users_us_prod";
      } else {
        this.messageCollection = "messages_us_qa";
        this.roomCollection = "rooms_us_qa";
        this.userCollection = "users_us_qa";
      }
    } else if (this.region === "PK") {
      if (this.stage === "PROD") {
        this.messageCollection = "messages_pk_prod";
        this.roomCollection = "rooms_pk_prod";
        this.userCollection = "users_pk_prod";
      } else {
        this.messageCollection = "messages_pk_qa";
        this.roomCollection = "rooms_pk_qa";
        this.userCollection = "users_pk_qa";
      }
    }
    this.userAuth();
    this.createRoom();

    // this.findMessages();
  }
};
</script>

<style lang="scss" scoped></style>
